@import "~core/utilities/fonts";

$universfonts: (
    100: Linotype-UniversLTStd-Light,
    200: Linotype-UniversLTStd-Light,
    300: Linotype-UniversLTStd-Light,
    400: Linotype-UniversLTStd,
    500: Linotype-UniversLTStd,
    700: Linotype-UniversLTStd-Bold,
    800: Linotype-UniversLTStd-Bold,
    900: Linotype-UniversLTStd-Black,
);
@mixin font-face($weight, $univers) {
    @font-face {
        font-family: 'Univers';
        font-style: normal;
        font-weight: $weight;
        src: url("../fonts/#{$univers}.otf"),
            url("../fonts/#{$univers}.otf?#iefix") format("opentype")
    }
}
@each $weight, $univers in $universfonts {
	@include font-face($weight, $univers);
}
