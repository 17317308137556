@import "../variables";
@import "~core/bootstrap-custom-import";

.store-list-container {
    a.active {
        color: $brand-secondary;
    }

    .nav-tabs {
        gap: 10px;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            flex-direction: column;
            padding-right: calc(#{$spacer} * 2);
            border-right: 1px solid $border-color;
            border-bottom: none;
        }

        @include media-breakpoint-down(sm) {
            justify-content: center;
            border-bottom: 1px solid $border-color;
        }
    }

    .state-nav-container {
        display: flex;
        align-items: start;
        flex-direction: column;
        max-width: fit-content;
    }

    .state-nav {
        @include media-breakpoint-up(md) {
            width: 140px;
        }
    }

    .state-tab-content {
        > .active {
            display: flex;
        }
    }
}
