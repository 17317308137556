.vertexError {
	background-color: $off-white;
	padding:20px;
	color: $red;
}
.vertexHint {
	padding:10px 0;
	text-align:left;
	font-size:12px;
	color: $red;
}
.vertexSuggestions {
	background-color: $off-white;
	padding:20px;

	#vertex-suggestions {
		width: 100%;
	}
}