@import "~core/product/grid";

.refinements {
    .values.content {
        button {
            span, a:hover, .text-link:hover {
                display: inline;
                text-decoration: none;
            }
        }
    }
}
