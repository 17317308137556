@import "~core/components/card";

.card {
    .card-header {
        .product-line-item {
            padding: 20px;
            
            .line-item-header {
                margin-bottom: 5px;
            }

            .product-line-item-details {
                .item-image {
                    width: 100px;

                    .product-image {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}