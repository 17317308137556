@import "~core/components/quickView";

.modal-dialog.quick-view-dialog {
    @include media-breakpoint-up(sm) {
        max-width: $modal-sm-max-width;
    }
    @include media-breakpoint-up(lg) {
        max-width: $modal-lg-max-width;
        margin: $modal-margins-desktop;
    }
    @include media-breakpoint-up(xl) {
        max-width: $modal-xl-max-width;
    }
}
